import React from 'react';

import H2 from '../H2';
import Wrapper from './Wrapper';
import ShareButton from './ShareButton';

function Share() {
  return (
    <Wrapper>
      <H2>Wanna watch live cams now?</H2>
      <ShareButton
        href={`https://voyeurhouse.com/live/`}
        aria-label="Share on Facebook"
      >
        Join VoyeurHouse
      </ShareButton>
    </Wrapper>
  );
}

export default Share;
